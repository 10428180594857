<template>
	<div class="mt-10 top-line">
		<div class="mt-30 full-height">
			<ul
				v-if="user.agency_type == 'A001001'"
				class="box-site"
			>
				<li
					class="box radius-20 box-shadow justify-space-between-in"
				>
					<div>
						<h6>사이트 명</h6>
						<div><input
							v-model="item.site_name"
							class="input-box-inline"
						></div>
					</div>
					<div class="mt-10">
						<h6>도메인</h6>
						<div><input
							v-model="item.site_domain"
							class="input-box-inline"
						></div>
					</div>
					<div class="mt-10">
						<h6>연결 아이디</h6>
						<div><input
							v-model="item.site_id"
							class="input-box-inline"
						></div>
					</div>
					<div class="mt-30">
						<button
							class="btn btn-primary"
							@click="postSite"
						>등록</button>
					</div>
				</li>
				<li
					v-for="(site, s_index) in site_list"
					:key="'site_' + s_index"
					class="box radius-20 box-shadow justify-space-between-in"
				>
					<div>
						<h6>사이트 명</h6>
						<div><input
							v-model="site.site_name"
							class="input-box-inline"
						></div>
					</div>
					<div class="mt-10">
						<h6>도메인</h6>
						<div><input
							v-model="site.site_domain"
							class="input-box-inline"
						></div>
					</div>
					<div class="mt-10">
						<h6>연결 아이디</h6>
						<div><input
							v-model="site.site_id"
							class="input-box-inline"
						></div>
					</div>
					<div class="mt-10">
						<h6>사용 여부</h6>
						<div class="justify-center box">
							<button
								class="pa-5-10 "
								:class="site.is_use == 1? 'bg-green' : 'bg-default'"
								@click="site.is_use = 1"
							>사용</button>
							<button
								class="pa-5-10 "
								:class="site.is_use == '0'? 'bg-red' : 'bg-default'"
								@click="site.is_use = 0"
							>미사용</button>
						</div>
					</div>
					<div class="mt-30">
						<button
							class="btn btn-primary"
							@click="putSite(site, s_index)"
						>수정</button>
					</div>
				</li>
			</ul>

			<div
				v-else
			>
				<ul
					class="box-site"
				>
					<li
						v-for="(site, s_index) in site_avail_list"
						:key="'site_' + s_index"
						class="box radius-20 box-shadow justify-space-between-in"
						:class="{'bg-green' : site.is_set}"
					>
						<div>
							<h6>사이트 명</h6>
							<div class="">{{ site.site_name}} </div>
						</div>
						<div class="mt-10">
							<h6>도메인</h6>
							<div class="">{{ site.site_domain}}</div>
						</div>
						<div class="mt-10">
							<h6>연결 아이디</h6>
							<div class="">{{ site.site_id}}</div>
						</div>
						<div class="mt-30">
							<div class="">
							</div>
							<button
								class="btn btn-primary"
								@click="setSite(site, s_index)"
							>선택</button>
						</div>
					</li>
				</ul>
			</div>
			<Empty
				v-if="user.agency_type != 'A001001' && site_list.length < 1"
			></Empty>
		</div>
	</div>
</template>

<script>

import Empty from "../Layout/Empty";
import {Base64} from "js-base64";
export default{
	name: 'Main'
	,props: ['Axios', 'TOKEN', 'date', 'user', 'codes', 'site_list']
	,components: {Empty }
	,data: function(){
		return {
			program: {
				name: 'Index'
				,top: true
				,title: true
				,search: false
				,bottom: false
			}
			, items: []
			, item: {

			}
			, site_avail_list: []
		}
	}
	,computed: {

	}
	,methods: {
		postSite: async function(){
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postSite'
					,data: this.item
				})

				if(result.success){
					this.site_list.push(result.data)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, putSite: async function(item){
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/putSite'
					,data: item
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, setSite: function(site){

			if(confirm(site.site_name + '으로 연결하시겠습니까?')){

				this.site_avail_list.filter( (item) => {
					if(item.site_agency_uid == site.site_agency_uid){
						this.$set(item, 'is_set', true)
						sessionStorage.setItem('site_info', Base64.encode(JSON.stringify(item)))
						this.$router.push({ name: 'Dashboard'})
					}else{
						this.$set(item, 'is_set', false)
					}
				})
			}
		}
		, getPgList: async function(){
		try{
			this.$bus.$emit('on', true)
			const result = await this.$request.init({
				method: 'get'
				,url: 'management/getAvailableSiteList'
				,data: {
					agency_type: this.user.agency_type
					, upper_id: this.user.upper_id
					, agency_id: this.user.agency_id
				}
			})
			if(result.success){
				this.site_avail_list = result.data
			}else{
				throw result.message
			}
		}catch(e){
			this.$bus.$emit('notify', { type: 'error', message: e})
		}finally {
			this.$bus.$emit('on', false)
		}
	}
	}
	,created: function(){
		this.$emit('onLoad', this.program)

		if(this.user.agency_type != 'A001001'){
			this.$router.push({ name: 'Dashboard'})
		}
	}
}

</script>

<style>

.label { color: white; border-radius: 5px; padding: 3px 7px 3px; line-height: 12px !important; font-size: 10px;}
.label-new { background-color: #0D47A1;}
.label-hot { background-color: #e64a19;}
.label-recomm { background-color: #0f9d58;}
.label-season { background-color: #00b0ff;}


.box-site {

}

.box-site li {
	width: 300px;
	height: 300px;
	padding: 20px;
	display: inline-block;
	margin-right: 20px;
}
</style>
